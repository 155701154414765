import HorizontalCard from "../layouts/HorizontalCard";
import graphic8 from "../assets/graphic8.png";
import style from "./metrics.module.css";

const Metrics = () => {
  return (
    <div className={`${style.container}`}>
      <HorizontalCard>
        <div className={`${style.textDiv}`}>
          <p></p>
          <h1>Tokenomics</h1>
          <p>
            The Dragon token (DRAGON) has a total supply of 888 million and is
            distributed through a fair and transparent process, with allocations
            for community incentives, development, liquidity, presale and small
            private sale for to help kick up thing.
          </p>
        </div>
        <div>
          <img className={`${style.image}`} src={graphic8} alt="graphics" />
        </div>
      </HorizontalCard>
    </div>
  );
};

export default Metrics;
