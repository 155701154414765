import VerticalCard from "../layouts/VerticalCard";
import style from "./layers.module.css";
import { FaArrowRight } from "react-icons/fa";
const Buy = () => {
  return (
    <div className={`${style.container}`}>
      <h1 className={`${style.heading}`}>RoadMap to DRAGON</h1>
      <p>
        Be part of our mission to make crypto accessible to all with DRAGON
        layer, the most anticipated DeFi platform of 2024.
      </p>
      <div className={`${style.cardsContainer}`}>
        <VerticalCard>
          <div>
            <h1>Q2: DEX, Staking, Farming and Swap</h1>
          </div>
          <div className={`${style.links}`}>
            <p>1. Dex (Decentralized Exchange)</p>
            <p>
              Functionality <FaArrowRight />
              <span> Trade cryptocurrencies directly.</span>
            </p>
            <p>
              Details <FaArrowRight />
              <span>
                Order book, AMM, multiple trading pairs, real-time analytics.
              </span>
            </p>

            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Development, smart contract audits, beta testing, full launch.
              </span>
            </p>
            <p>2. Staking</p>
            <p>
              Functionality <FaArrowRight />
              <span> Earn rewards by staking tokens.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span>
                {" "}
                Multiple pools, user-friendly interface, transparent rewards.
              </span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Smart contract development, UI integration, security audits,
                launch.
              </span>
            </p>
            <p>3. Farming</p>
            <p>
              Functionality <FaArrowRight />
              <span> Earn rewards by staking tokens.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span>
                {" "}
                Diverse pools, reward multipliers, detailed analytics.
              </span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Liquidity pool contracts, integration, audits, public launch
              </span>
            </p>
            <p>4. Swap</p>
            <p>
              Functionality <FaArrowRight />
              <span> Quick and easy token swaps.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span>
                {" "}
                DEX integration, user-friendly interface, low slippage.
              </span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Liquidity pool contracts, integration, audits, public launch
              </span>
            </p>
          </div>
        </VerticalCard>
        <VerticalCard>
          <div>
            <h1>Q3: DCA and Limit Orders</h1>
          </div>
          <div className={`${style.links}`}>
            <p>1. DCA (Dollar-Cost Averaging)</p>
            <p>
              Functionality <FaArrowRight />
              <span> Automate regular cryptocurrency purchases.</span>
            </p>
            <p>
              Details <FaArrowRight />
              <span>
                Custom schedules, multi-crypto support, performance tracking.
              </span>
            </p>

            <p>
              Milestones <FaArrowRight />
              <span> Development, wallet integration, testing, launch.</span>
            </p>
            <p>2. Limit Orders</p>
            <p>
              Functionality <FaArrowRight />
              <span> Advanced orders, real-time updates, notifications.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span>
                {" "}
                Order system development, DEX integration, testing, launch.
              </span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Smart contract development, UI integration, security audits,
                launch.
              </span>
            </p>
            <div>
              <h1>Q4: DAO and P2P</h1>
            </div>
            <p>1. DAO (Decentralized Autonomous Organization)</p>
            <p>
              Functionality <FaArrowRight />
              <span> User-driven platform governance.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span>
                {" "}
                Token-based voting, proposal system, transparent results.
              </span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                Governance contracts, voting interface, test voting, full
                launch.
              </span>
            </p>
            <p>2. P2P (Peer-to-Peer) Transactions</p>
            <p>
              Functionality <FaArrowRight />
              <span>Direct user-to-user transactions.</span>
            </p>

            <p>
              Details <FaArrowRight />
              <span> Secure escrow, reputation system, varied transaction</span>
            </p>
            <p>
              Milestones <FaArrowRight />
              <span>
                {" "}
                P2P module development, profile integration, security testing,
                launch.
              </span>
            </p>
          </div>
        </VerticalCard>
      </div>
      <div className={`${style.summary}`}>
        <h1>Summary Timelime</h1>
        <p>Q2: Launch Dex, Staking, Farming and Swap</p>
        <p>Q3: Develop and launch DCA and Limit Orders.</p>
        <p>Q4: Implement and launch DAO and P2P transactions.</p>
      </div>
    </div>
  );
};

export default Buy;
