import BuyCard from "../layouts/BuyCard";
import style from "./buy.module.css";
import { FaArrowRight } from "react-icons/fa";
const Buy = () => {
  return (
    <div className={`${style.container}`}>
      <h1 className={`${style.heading}`}>Buy the DRAGON Token</h1>
      <p>
        Be part of our mission to make crypto accessible to all with DRAGON
        layer, the most anticipated DeFi platform of 2024.
      </p>
      <div className={`${style.cardsContainer}`}>
        <BuyCard>
          <div>
            <h1>Buy With Crypto</h1>
          </div>
          <div className={`${style.links}`}>
            <p>
              Buy On Dragon Layer <FaArrowRight />
            </p>
            <p>
              Buy on MEXC <FaArrowRight />
            </p>
          </div>
        </BuyCard>
        <BuyCard>
          <div>
            <h1>Buy With credit/debit card</h1>
          </div>
          <div className={`${style.links}`}>
            <p>
              Buy On Dragon Layer Onboard <FaArrowRight />
            </p>
          </div>
        </BuyCard>
      </div>
    </div>
  );
};

export default Buy;
