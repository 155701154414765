import About from "./about";
import Ecosystem from "./ecosystem";
import style from "./hero.module.css";

import { FaArrowRight } from "react-icons/fa";
const Hero = () => {
  return (
    <>
      <div className={`${style.container}`}>
        <h1 className={`${style.heading}`}>The DeFi Experience </h1>
        <h1 className={`${style.heading}`}> Of Tomorrow</h1>
        <p>
          Discover DRAGON – A comprehensive platform to buy, trade and earn
          crypto. Decentralized, secure and easy to use.
        </p>
        <div className={`${style.buttons}`}>
          <a
            href="https://app.uniswap.org/swap?outputCurrency=0x528757e34A5617Aa3AAbE0593225FE33669E921C&chain=base"
            target="_blank"
            rel="noreferrer"
          >
            <button>
              Buy DRAGON <FaArrowRight />
            </button>
          </a>
          <a
            href="https://www.dextools.io/app/en/base/pair-explorer/0xd53881caee96d3a94fd0e2eb027a05fd44d8c470?t=1718132286669"
            target="_blank"
            rel="noreferrer"
          >
            <button>
              Dextools <FaArrowRight />
            </button>
          </a>

          <a
            href="https://doc.dragonprotocol.io"
            target="_blank"
            rel="noreferrer"
          >
            View The Docs <FaArrowRight />
          </a>
        </div>
      </div>
      <About />
      <Ecosystem />
    </>
  );
};

export default Hero;
