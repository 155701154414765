import { useState } from "react";
import logo from "../assets/logo.png";
import style from "./header.module.css";
import MobileMenu from "./mobileMenu";
import { Link } from "react-router-dom";
const Header = () => {
  const [isActive, setIsActive] = useState({
    home: false,
    buy: false,
    metrics: false,
    layers: false,
    doc: false,
  });

  const onClickHandler = (e) => {
    let selectedOption = e.target.id;
    if (selectedOption === "home") {
      setIsActive({
        home: true,
      });
    } else if (selectedOption === "buy") {
      setIsActive({
        buy: true,
      });
    } else if (selectedOption === "metrics") {
      setIsActive({
        metrics: true,
      });
    } else if (selectedOption === "layer") {
      setIsActive({
        layers: true,
      });
    } else if (selectedOption === "doc") {
      setIsActive({
        doc: true,
      });
    }

    console.log(e.target.id);
  };
  return (
    <div className={`${style.header}`}>
      <img src={logo} alt="logo" className={`${style.image}`} />
      <ul className={`${style.menu}`}>
        <Link to="/">
          <li
            id="home"
            className={`${isActive.home ? style.selected : ""} ${
              style.navItem
            }`}
            onClick={onClickHandler}
          >
            Home
          </li>
        </Link>
        <Link
          to="/buy"
          className={`${isActive.buy ? style.selected : ""} ${style.navItem}`}
          id="buy"
          onClick={onClickHandler}
        >
          Buy
        </Link>
        <Link
          to="/metrics"
          id="metrics"
          className={`${isActive.metrics ? style.selected : ""} ${
            style.navItem
          }`}
          onClick={onClickHandler}
        >
          Dragon Metrics
        </Link>
        <Link
          to="/layers"
          id="layers"
          className={`${isActive.layer ? style.selected : ""} ${style.navItem}`}
          onClick={onClickHandler}
        >
          Dragon Layers
        </Link>

        <a
          href="https://doc.dragonprotocol.io"
          target="_blank"
          rel="noreferrer"
          id="doc"
          className={`${isActive.doc ? style.selected : ""} ${style.navItem}`}
          onClick={onClickHandler}
        >
          Doc
        </a>
      </ul>
      <a
        href="https://layer.dragonprotocol.io"
        target="_blank"
        rel="noreferrer"
      >
        <button className={`${style.dappButton}`}>Visit Layer</button>
      </a>
      <div>
        <a
          href="https://x.com/dragon_protocol?t=gdya_X-9BjBa1ZfwHtW8-w&s=09"
          target="_blank"
          rel="noreferrer"
          className={`${style.social}`}
        >
          D
        </a>
        <a
          href="https://t.me/dragonprotocolio"
          target="_blank"
          rel="noreferrer"
          className={`${style.social}`}
        >
          (
        </a>
      </div>

      <MobileMenu />
    </div>
  );
};

export default Header;
