import style from "./ecosystem.module.css";
import EcosystemCard from "../layouts/EcosystemCard";
import { FaArrowRight } from "react-icons/fa";
const Ecosystem = () => {
  return (
    <div className={`${style.container}`}>
      <p className={`${style.title}`}>Ecosystem</p>
      <h2 className={`${style.heading}`}>bringing DeFi to masses</h2>
      <p className={`${style.tagline}`}>
        Dragon is composed of two interdependent components: the DRAGON token
        and the DRAGON layer. Together, these elements create a symbiotic
        relationship that enhances the overall functionality and value of the
        Dragon ecosystem
      </p>

      <div className={style.VerticalCards}>
        <EcosystemCard>
          <div>
            <h1>The Dragon Token</h1>
            <h6>
              The DRAGON token serves as the backbone of the ecosystem,
              providing utility, governance, and incentives for users
            </h6>
          </div>
          <p>
            Learn About Dragon <FaArrowRight />
          </p>
        </EcosystemCard>
        <EcosystemCard>
          <div>
            <h1>The Dragon Layer</h1>
            <h6>
              The DRAGON layer offers a comprehensive suite of tools and
              services for buying, trading, and earning cryptocurrency, all
              within a decentralized, secure, and user-friendly environment.
            </h6>
          </div>
          <p>
            Learn About Layer <FaArrowRight />
          </p>
        </EcosystemCard>
      </div>
    </div>
  );
};

export default Ecosystem;
