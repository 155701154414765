import { Route, Routes } from "react-router-dom";

import Header from "./components/header";
import Hero from "./components/hero";
import Buy from "./components/buy";
import Metrics from "./components/metrics";
import Layers from "./components/layers";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/metrics" element={<Metrics />} />
        <Route path="/layers" element={<Layers />} />
      </Routes>
      {/* <Hero /> */}
    </div>
  );
}

export default App;
