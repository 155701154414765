import { useState } from "react";
import { IoMenuSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import style from "./mobileMenu.module.css";
import { Link } from "react-router-dom";

const MobileMenu = () => {
  const [open, SetIsOpen] = useState(false);
  const onClickHandler = () => {
    SetIsOpen(false);
  };
  const onCloseHandler = () => {
    SetIsOpen(!open);
  };
  return (
    <div className={`${style.container}`}>
      {open ? (
        <div className={`${style.menu}`}>
          <ul className={`${style.menu}`}>
            <div className={`${style.home}`}>
              <Link
                to="/"
                id="home"
                className={` ${style.navItem} ${style.homeButton}`}
                onClick={onClickHandler}
              >
                Home
              </Link>
              <h4 onClick={onCloseHandler}>
                <IoMdClose />
              </h4>
            </div>

            <Link
              to="/buy"
              className={` ${style.navItem}`}
              id="buy"
              onClick={onClickHandler}
            >
              Buy
            </Link>
            <Link
              to="/metrics"
              id="metrics"
              className={` ${style.navItem}`}
              onClick={onClickHandler}
            >
              Dragon Metrics
            </Link>
            <Link
              to="/layers"
              id="layers"
              className={` ${style.navItem}`}
              onClick={onClickHandler}
            >
              Dragon Layers
            </Link>

            <a
              href="https://doc.dragonprotocol.io"
              target="_blank"
              rel="noreferrer"
              id="doc"
              className={` ${style.navItem}`}
              onClick={onClickHandler}
            >
              Doc
            </a>
            <div className={`${style.navItem} ${style.socials}`}>
              <a
                href="https://https://x.com/dragon_protocol"
                target="_blank"
                rel="noreferrer"
                className={`${style.social}`}
              >
                D
              </a>
              <a
                href="https://t.me/dragonprotocolio"
                target="_blank"
                rel="noreferrer"
                className={`${style.social}`}
              >
                (
              </a>
            </div>
          </ul>
        </div>
      ) : (
        <h1 className={`${style.hidden}`} onClick={onCloseHandler}>
          <IoMenuSharp />
        </h1>
      )}
    </div>
  );
};

export default MobileMenu;
