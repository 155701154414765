import HorizontalCard from "../layouts/HorizontalCard";
import VerticalCard from "../layouts/VerticalCard";
import style from "./about.module.css";

import graphic1 from "../assets/graphic1.png";
import graphic2 from "../assets/graphic2.png";
import graphic3 from "../assets/graphic3.png";
import graphic4 from "../assets/graphic4.png";
import graphic5 from "../assets/graphic5.png";
import graphic6 from "../assets/graphic6.png";
import graphic7 from "../assets/graphic7.png";

const About = () => {
  return (
    <div className={`${style.container}`}>
      <p className={`${style.title}`}>Vision</p>
      <h2 className={`${style.heading}`}>Introducing DRAGON</h2>
      <p className={`${style.tagline}`}>
        We are introducing DRAGON – a complete platform to buy, trade, and earn
        crypto Decentralized, secure, and user-friendly.
      </p>

      <HorizontalCard>
        <div className={`${style.textDiv}`}>
          <p>DRAGON Onboard</p>

          <h1>Buy any token with credit/debit card</h1>
          <p>
            Use any credit or debit card to buy crypto. We support token trading
            on the Base network.
          </p>
        </div>
        <div>
          <img className={`${style.image}`} src={graphic1} alt="graphics" />
        </div>
      </HorizontalCard>
      <div className={style.VerticalCards}>
        <VerticalCard>
          <div>
            <p>DRAGON Exchange</p>
            <h1>Exchange tokens at the best price</h1>
            <p>
              Enhanced by AI to secure the best rates, our platform simplifies
              the token swapping process like never before
            </p>
          </div>
          <img src={graphic3} alt="graphics" />
        </VerticalCard>
        <VerticalCard>
          <div>
            <p>DRAGON P2P</p>
            <h1>Farewell to centralized P2P systems</h1>
            <p>
              Engage with buyers and sellers on our secure platform to buy
              cryptocurrency with your local currency.
            </p>
          </div>
          <img src={graphic2} alt="graphics" />
        </VerticalCard>
      </div>
      <div className={style.VerticalCards}>
        <VerticalCard>
          <div>
            <p>DRAGON Earn</p>
            <h1>Effortlessly earn DRAGON through staking and farming.</h1>
            <p>
              Stake or farm your DRAGON tokens to boost your earnings simply by
              depositing them on the platform.
            </p>
          </div>
          <img src={graphic4} alt="graphics" />
        </VerticalCard>
        <VerticalCard>
          <div>
            <p>DRAGON</p>
            <p className={`${style.contract}`}>
              CA 0x528757e34A5617Aa3AAbE0593225FE33669E921C
            </p>
            <p>
              Ensure your portfolio's security with our limit orders and DCA,
              plus much more
            </p>
          </div>
          <img src={graphic5} alt="graphics" />
        </VerticalCard>
      </div>

      <HorizontalCard>
        <div className={`${style.textDiv}`}>
          <p>DRAGON Spend</p>

          <h1>Spend your hard-earned crypto</h1>
          <p>
            Partner with us to redeem your tokens for flights, services, and
            physical goods through our future collaborations
          </p>
        </div>
        <div>
          <img className={`${style.image}`} src={graphic6} alt="graphics" />
        </div>
      </HorizontalCard>
      <HorizontalCard>
        <div className={`${style.textDiv}`}>
          <h1>All of your favorite assets in one single page</h1>
        </div>
        <div>
          <img className={`${style.image}`} src={graphic7} alt="graphics" />
        </div>
      </HorizontalCard>
    </div>
  );
};

export default About;
